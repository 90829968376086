import './assets/css/style.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutPage from "./pages/LayoutPage";
import MainWelcomePage from "./pages/MainWelcomePage";
import SupportPage from "./pages/SupportPage";
import PrivacyPage from "./pages/PrivacyPage";
import NoPage from "./pages/NoPage";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LayoutPage />}>
            <Route index element={<MainWelcomePage />} />
            <Route path="support" element={<SupportPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="*" element={<NoPage/>} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
